<script setup lang="ts">
import { PostProps } from "./Post.props"
import { truncateString } from "~/utils/truncateString"

defineProps<PostProps>()

const dynamicId = computed(() => `link-${Math.random()}`)
</script>

<template>
  <div
    @click="navigateTo(path)"
    class="card-blog-card flex cursor-pointer flex-col justify-between"
  >
    <div>
      <div class="wrapper">
        <UtilsWrapperImage
          v-if="image"
          class="wrapper-image-fixed-112 wrapper-image-full-container-centered"
          v-bind="image"
        />
      </div>

      <h5 v-if="title" class="pig-bold mt-2">
        <AppLink :to="path" :id="dynamicId">
          {{ truncateString(title, 40) }}
        </AppLink>
      </h5>

      <div v-if="tags?.length" class="mt-2 flex flex-wrap gap-4">
        <BadgeTag v-for="tag in tags" :key="tag.text" v-bind="tag" />
      </div>
    </div>

    <div>
      <UtilsMarkdown
        v-if="text"
        class="mouse mt-2 line-clamp-4 h-20 md:text-wireframe-grey-70"
        :content="text"
      />

      <UtilsButton
        :text="$t('card.blogCard.readTheArticle')"
        theme="link-black"
        type="button"
        order="last"
        class="move-left mt-2 inline-flex"
      >
        <template #icon>
          <IconsArrowRight class="text-black h-5 w-5" />
        </template>
      </UtilsButton>
    </div>
  </div>
</template>

<style scoped lang="scss">

.move-left {
  :deep(.btn__container) {
    @apply cursor-pointer p-0 py-[2px];
  }
}

</style>
